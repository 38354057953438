import React from 'react'
import './Bale.scss'
import { useTranslation } from "react-i18next";
import PropertyField from '../components/PropertyField/PropertyField';
import { AuthContext } from '../context/AuthContext';
import { firestore } from "../context/firebase";
import { onSnapshot, updateDoc, setDoc, doc } from "firebase/firestore";
import { useNavigate} from 'react-router-dom';

import { fetchImageUrl } from "../utils/ImgUtils.js"
import BaleCard from '../components/BaleCard/BaleCard.jsx';
import LoadingIcon from '../components/LoadingIcon/LoadingIcon.jsx';

// Importing icons
import back_icon        from '../assets/icons/white/back_small.png';
import notes_icon       from '../assets/icons/white/document.png';
import logo_icon        from '../assets/logos/upcircle_logo_circle.png';
import alert_off_icon   from '../assets/icons/white/alert_no_circle.png';
import alert_on_icon    from '../assets/icons/red/alert.png';
import label_icon       from '../assets/icons/white/label.png';
import material_icon    from "../assets/icons/white/piechart.png"
import quality_icon     from "../assets/icons/white/clean.png"

export default function Bale({ baleId, deliveryId, returnToDelivery, bales, setActiveBaleId }) {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets } = React.useContext(AuthContext);
    const [bale, setBale] = React.useState({});
    const [baleImageData, setBaleImageData] = React.useState(null);
    const [imageSize, setImageSize] = React.useState({ width: 0, height: 0, scale: 1 });
    const [screenSize, setScreenSize] = React.useState({ width: window.innerWidth, height: window.innerHeight });
    const unsubscribe = React.useRef(null);

    const navigate = useNavigate();

    React.useEffect(() => {
        /* If no bale is passed, navigate back to deliveries page (happens when refreshing page) */
        if (!baleId) {
            navigate("/deliveries");
        }

        /* Subscribe to bale data */
        if (unsubscribe.current) unsubscribe.current();
        const baleRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "bales", baleId);
        const newUnsubscribe = onSnapshot(baleRef, (snapshot) => {
            if (snapshot.empty) return;
            setBale(snapshot.data());
        });
        unsubscribe.current = () => newUnsubscribe();
        
        // Handle window resize to correctly size image and bounding boxes
        function handleResize() {
            setScreenSize({width: window.innerWidth, height: window.innerHeight});
        };
        window.addEventListener('resize', handleResize);
        return () => {window.removeEventListener('resize', handleResize); unsubscribe.current();}
    }, [baleId]);

    React.useEffect(() => {
        /* Load the image whenever the image URL changes */
        if (!bale) {
            return;
        }

        setBaleImageData(null);
        fetchImageUrl(bale.image_URL).then((url) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                setBaleImageData(img);
            }
        })
    }, [bale?.image_URL]);

    React.useEffect(() => {
        /* Calculate image size based on screen size and image aspect ratio */
        if (!baleImageData) {
            return;
        }

        const WIDTH_BORDER = 40;
        const HEIGHT_BORDER = 350;
        let scale = (screenSize.width - WIDTH_BORDER) / baleImageData.width;
        if (baleImageData.height * scale > screenSize.height - HEIGHT_BORDER) {
            scale = (screenSize.height - HEIGHT_BORDER) / baleImageData.height;
        }
        setImageSize({ width: baleImageData.width * scale, height: baleImageData.height * scale, scale: scale });
    }, [baleImageData, screenSize]);

    function handleFieldChange(event) {
        /* Update properties of a bale */
        const { name, value } = event.target;
        const baleRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "bales", baleId);
        updateDoc(baleRef, { [name]: value });
    }

    function getNextBale() {
        /* Get the next bale ID in the list of bales (with regards to the baleId) */
        const baleIds = Object.keys(bales).sort();
        const currentIndex = baleIds.indexOf(baleId);
        if (currentIndex === -1) return baleId;

        const nextBaleId = currentIndex < baleIds.length - 1 ? baleIds[currentIndex + 1] : baleId;
        setActiveBaleId(nextBaleId);
    }

    function getPreviousBale() {
        /* Get the previous bale ID in the list of bales (with regards to the baleId) */
        const baleIds = Object.keys(bales).sort();
        const currentIndex = baleIds.indexOf(baleId);
        if (currentIndex === -1) return baleId;

        const previousBaleId = currentIndex > 0 ? baleIds[currentIndex - 1] : baleId;
        setActiveBaleId(previousBaleId);
    }

    function handleLabelChange(e) {
        /* Update the label of a bale when a select dropdown is changed */
        const baleRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "bales", baleId);
        setDoc(baleRef, { 
            labels: {
                [e.target.name]: {
                    [currentUser.uid]: e.target.value
                }
            },
            labelled: true,
            request_label: false,
         }, { merge: true });
    }

    // Wait for bale data to load
    if (!bale) {
        return null;
    }

    return (
        <div className="bale">
            <div className="bale--header">
                <div onClick={returnToDelivery} className="bale--back">
                    <img src={back_icon} alt="Back" className="bale--back-icon"/>
                </div>
            </div>
            <div className="bales-navigation">
                <div className="bales-scroll-bar">
                    {Object.entries(bales)
                        .sort((a, b) => {
                            return a[1]?.flagged && !b[1]?.flagged ? -1 : 
                            !a[1]?.flagged && b[1]?.flagged ? 1 : a[0].localeCompare(b[0]);
                        }).map(([id, bale]) => {
                        return (
                            <div className={`bale-card-wrapper ${baleId === id && "active-bale"}`} onClick={() => {setActiveBaleId(id)}} key={id}>
                                <BaleCard key={id} 
                                    image_URL={bale.image_URL}
                                    flagged={bale.flagged}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="bale--img-container"
                style={{ width: imageSize.width ?? screenSize.width*0.9, height: imageSize.height ?? screenSize.height*0.5 }}
            >
                <img className="bale--img" 
                    src={baleImageData?.src ?? ""} 
                    style={{ display: baleImageData ? 'block' : 'none' }}
                />
                {!baleImageData && <LoadingIcon />}
                <div className="bale-alert"
                    onClick={() => {handleFieldChange({target: {name: "flagged", value: !bale.flagged}})}}
                    style={{ opacity: bale.flagged ? 1 : 0.5, display: baleImageData ? 'block' : 'none' }}
                >
                    <img src={bale?.flagged ? alert_on_icon : alert_off_icon}/>
                </div>
            </div>

            <div className="bale--footer">
                <button className="bale-swipe-button prev" onClick={getPreviousBale}>
                    <img src={back_icon}/> 
                </button>
                <div className="bale--properties">
                    {currentUser.info.user_flags && ['labeler', 'admin'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <div className="labeling-container">
                        {Object.keys(labelsets || {}).map(labelsetId => {
                            const labelset = labelsets[labelsetId];
                            if (!labelset?.enable_labelling) {
                                return null;
                            }
                            // Only supporting single-choice labelsets for now
                            if (labelset?.type ?? "single-choice" !== "single-choice") {
                                return null;
                            }
                            // Skip empty labelsets
                            if (!labelset.labels) {
                                return null;
                            }
                            // Create options object: key is the label ID, value is the display name
                            const options = Object.entries(labelset.labels).reduce((acc, [key, value]) => {
                                acc[key] = value.display_name;
                                return acc;
                            }, {});
                            // Select icon
                            const icon = labelsetId === "materials" ? material_icon : 
                                labelsetId ==="quality" ? quality_icon : label_icon;

                            const value = bale?.labels?.[labelsetId]?.[currentUser.uid] ?? "";
                            
                            return (<PropertyField 
                                key={labelsetId}
                                icon={icon}
                                title={labelset.name}
                                value={value}
                                name={labelsetId}
                                type="select"
                                options={options}
                                handleChange={handleLabelChange}
                                editable={true}
                            />)
                        })}
                    </div>
                    }

                    <div className="labeling-container">
                        <PropertyField 
                            icon={notes_icon} 
                            title={t("property_notes")}
                            value={ bale.notes } 
                            name="notes"
                            type="textarea"
                            handleChange={handleFieldChange}
                            editable={true}
                            grid_column_span={2}
                            grid_row_span={2}
                        />
                    </div>
                </div>
                <button className="bale-swipe-button next" onClick={getNextBale}>
                    <img src={back_icon}/> 
                </button> 
            </div>
        </div>
    )
}