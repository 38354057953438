import React from 'react'
import './PropertyField.scss'
import { useTranslation } from "react-i18next";

import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { onSnapshot, setDoc, doc } from "firebase/firestore";
import { firestore } from "../../context/firebase";

import PropertyField from './PropertyField';

import supplier_icon from '../../assets/icons/white/delivery.png';

export default function SupplierPropertyField({ value, handleDeliveryFieldChange }) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [showAddOptionModal, setShowAddOptionModal] = React.useState(false);
    const [options, setOptions] = React.useState({});
    const supplierIdRef = React.useRef(null);
    const supplierNameRef = React.useRef(null);

    // Fetch the supplier labelset from the database
    React.useEffect(() => {
        const supplierLabelsetRef = doc(firestore, "clients", currentUser.company.company, "labelsets", "supplier");
        const unsubscribe = onSnapshot(supplierLabelsetRef, (doc) => {
            const newOptions = {};
            Object.entries(doc.data()?.labels ?? {}).forEach(([key, label]) => {
                newOptions[key] = label.full_name;
            });
            // Check if the current value is not in the options list. If not, add it.
            if (value && !Object.keys(newOptions).includes(value)) {
                newOptions[value] = value;
            }
            setOptions(newOptions);
        });

        return unsubscribe;
    }, []);

    function addOption() {
        /* Add a new supplier to the supplier labelset */

        // Check that the input fields are not empty
        if (!supplierIdRef.current.value || supplierIdRef.current.value.length === 0 || 
            !supplierNameRef.current.value || supplierNameRef.current.value.length === 0) {
            setShowAddOptionModal(false);
            return;
        }

        // Check if ID already exists
        if (Object.keys(options).includes(supplierIdRef.current.value)) {
            setShowAddOptionModal(false);
            return;
        }

        const supplierLabelsetRef = doc(firestore, "clients", currentUser.company.company, "labelsets", "supplier");
        setDoc(supplierLabelsetRef, {
            labels: {
                [supplierIdRef.current.value]: {
                    id: supplierIdRef.current.value,
                    full_name: `${supplierIdRef.current.value} ${supplierNameRef.current.value}`,
                    display_name: supplierNameRef.current.value,
                }
            }
        }, { merge: true });

        // Change delivery supplier to the new supplier
        handleDeliveryFieldChange({ target: { name: "supplier", value: supplierIdRef.current.value } });

        setShowAddOptionModal(false);
    }

    return (
        <>
            <PropertyField
                icon={supplier_icon} 
                title={t("property_supplier")}
                value={value} 
                name={"supplier"} 
                type="select-extendible-custom"
                options={options}
                handleChange={handleDeliveryFieldChange}
                onAddOption={() => {setShowAddOptionModal(true)}}
                editable={true}
            />

            <Modal 
                show={showAddOptionModal}
                onHide={() => setShowAddOptionModal(false)}
                backdrop = "static"
                className = "add-option-modal"
            >
                <div className="modal-box">
                    <Modal.Header>
                        <Modal.Title>{t("property_field_extendable_new")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="supplier-input-grid">
                            <p>ID</p>
                            <p>Name</p>
                            <input type={"text"} ref={supplierIdRef}/>
                            <input type={"text"} ref={supplierNameRef} /> 
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="modal-button" variant="secondary" onClick={() => setShowAddOptionModal(false)}>
                            {t("general_cancel")}
                        </Button>
                        <Button className="modal-button" variant="danger" onClick={addOption}>
                            {t("general_add")}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    )
}