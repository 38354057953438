// Importing react and style
import './style.scss';
import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// Importing Custom Pages
import Login from './pages/Login';
import Home from './pages/Home';
import Deliveries from './pages/Deliveries';
import Delivery from './pages/Delivery';
import LoadingScreen from './pages/LoadingScreen';

// Importing context provider. 
import { AuthContext } from './context/AuthContext';

function App() {
  // Creating the user 
  const { authUser, currentUser, userLoaded } = useContext(AuthContext);

  // Creating a protected route to only allow logged-in users to access the dashboard. 
  const ProtectedRoute = ({children}) => {
    if(!authUser){
      return <Navigate to="/login"/>
    }
    // else
    return children
  }

  // Preventing the pinch zoom on the mobile devices. -> Currently enabled on request
  // document.addEventListener('touchmove', function (event) {
  //   if (event.scale !== 1) { event.preventDefault(); }
  // }, { passive: false });

  if (authUser && !userLoaded) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<ProtectedRoute><Deliveries/></ProtectedRoute>} /> {/* Wrapping home in the protected route */}
          <Route path="login" element={<Login/>} />
          <Route path="deliveries" element={<ProtectedRoute><Deliveries/></ProtectedRoute>} />
          <Route path="delivery" element={<ProtectedRoute><Delivery/></ProtectedRoute>} />
          <Route path="loading" element={<ProtectedRoute><LoadingScreen /></ProtectedRoute>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;